import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from 'date-fns';

const Booking = () => {
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 9),
  );
  const [selectedTrip, setSelectedTrip] = useState('Single Trip');
  const [selectedSeries, setSelectedSeries] = useState('Series 1'); 

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleTripSelection = (trip) => {
    setSelectedTrip(trip);
  };

  const handleSeriesChange = (event) => {
    setSelectedSeries(event.target.value);
  };
  
  const filterPassedTime = (time) => {
    const selectedTime = new Date(time);
    const startTime = setHours(setMinutes(new Date(), 0), 8);
    const endTime = setHours(setMinutes(new Date(), 0), 19);
    return selectedTime >= startTime && selectedTime <= endTime;
  };

  return (
    <>
      <div className="container-fluid fluid-padding py-5 mt-5 booking-page">
        <div className="row">
          <div className="col-12">
            <h3 className="">Book Your Package Now</h3>
          </div>
          <div className="col-12">
            <p className="mt-3 mb-0 text-center">
              Choose Your Package:
            </p>
            <div className="package-options text-center mt-3">
              <button
                className={selectedTrip === 'Single Trip' ? 'active' : ''}
                onClick={() => handleTripSelection('Single Trip')}
              >
                Single Trip
              </button>
              <button
                className={selectedTrip === 'Island Hopping' ? 'active' : ''}
                onClick={() => handleTripSelection('Island Hopping')}
              >
                Island Hopping
              </button>
            </div>
            <div className='row mt-5 justify-content-center form-section'>
              <div className="col-md-7 d-flex packages-number">
                <p>Name:</p>
                <input type="text" />
              </div>
              <div className="col-md-7 d-flex packages-number">
                <p>Contact No:</p>
                <input type="tel" />
              </div>
              {selectedTrip === 'Single Trip' && (
                <div className="col-md-7 d-flex packages-number">
                  <p>Series:</p>
                  <select
                    value={selectedSeries}
                    onChange={handleSeriesChange}
                    className="form-control"
                  >
                    <option value="Series 1">Series 1</option>
                    <option value="Series 2">Series 2</option>
                  </select>
                </div>
              )}
              <div className="col-md-7 d-flex packages-number">
                <p>Selected Time:</p>
                <input
                  id="datepicker-input"
                  type="text"
                  value={startDate.toLocaleString()} // Display date and time in input field
                  readOnly // Prevent direct editing of the input field
                  className="form-control"
                />
              </div>
            </div>
            <div className="date-picker-container" >
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              inline // Display the calendar inline
              filterTime={filterPassedTime}
              className="form-control"
            />
          </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default Booking;