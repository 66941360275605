import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from './context/authContext';
import { UserContextProvider } from './context/userContext';
import "./assets/css/bootstrap.min.css";
import "./assets/css/theme.css";
import "./assets/css/style.css";

ReactDOM.render(
  <AuthContextProvider>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")  
);