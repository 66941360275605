import React, { Component } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import watersportcar1 from "../assets/img/jet-img/watersportcarvideo1.mp4";
import watersportcar2 from "../assets/img/jet-img/watersportcarvideo2.mp4";
import watersportcar3 from "../assets/img/jet-img/watersportcarvideo3.mp4";

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const HeroBanner = () => {
  
  return (
    <>
      <div className="container-fluid video-section">
        <div className="row">
            <section>
              <div className="videoContent">
                <h1>Check Out Our Water Sports Car In Langkawi</h1>
                <h1>The leading water sport car in Langkawi </h1>
              </div>
              <video loop autoPlay muted className='w-100'>
                    <source src={watersportcar1} type="video/mp4" />
              </video> 
              <div className="buttonWrap">
                <button onClick={(e) => { window.location.href= "/booking"; }}>Booking</button>
                <button>Contact Us</button>
              </div>
            </section>

            {/* <section id="series1">
              <div className="videoContent">
                <h1>Series 1</h1>
                <h1>A luxury recreational watercraft that looks, feels, and drives like a sports car. </h1>
              </div>
              <video loop autoPlay muted className='w-100'>
                <source src={watersportcar2} type="video/mp4" />
              </video> 
              <div className="buttonWrap">
                <button onClick={(e) => { window.location.href= "/booking"; }}>Booking</button>
                <button>Contact Us</button>
              </div>
            </section>

            <section id="series2">
              <div className="videoContent">
                <h1>Series 2</h1>
                <p>A luxury recreational watercraft that looks, feels, and drives like a sports car. </p>
              </div>
              <video loop autoPlay muted className='w-100'>
                    <source src={watersportcar3} type="video/mp4" />
              </video> 
              <div className="buttonWrap">
                <button onClick={(e) => { window.location.href= "/booking"; }}>Booking</button>
                <button>Contact Us</button>
              </div>
            </section> */}
            
        </div>
      </div>
    </>
  );

};

export default HeroBanner;