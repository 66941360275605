import React, { Component } from "react";
import { NavLink } from 'react-router-dom';


import singleTrip from "../assets/img/jet-img/watersportcarpackage1.webp";
import islandHopping from "../assets/img/jet-img/watersportcarpackage2.webp";
import carRight from "../assets/img/jet-img/series2WaterSportCar.webp";
import jetVideo1 from "../assets/img/jet-img/jetVideo2.mp4";
import jetVideo2 from "../assets/img/jet-img/jetVideo3.mp4";

const Packages = () => {
  
  return (
    <>
      <div id="package" className="container-fluid fluid-padding py-5 package-section">

        <div className="row">
          <div className="col-12">
            {/* <h3>Our Packages</h3> */}
          </div>
        </div>
        <div className="packageFlex">
          <div className="packageWrap">
              {/* <div className="package-container text-center">
                <h5>Single Trip</h5>
                <p className="mb-0">30 minutes</p>
                <p className="mb-0">RM880</p>
                <NavLink to="/booking">
                  <button>BOOK NOW</button>
                </NavLink>
              </div> */}
              <div>
                <img src={singleTrip} alt="Water Sport Car In Langkawi"></img>
                <div className="package-container text-center">
                  <h5>Single Trip</h5>
                  <p className="mb-0">30 minutes</p>
                  <p className="mb-0">RM1200</p>
                  <NavLink to="/booking">
                    <button>BOOK NOW</button>
                  </NavLink>
                </div>
              </div>
              {/* <div className="package-container text-center">
                <h5>Island Hopping</h5>
                <p className="mb-0">3 Hours</p>
                <p className="mb-0">RM2600</p>
                <NavLink to="/booking">
                  <button>BOOK NOW</button>
                </NavLink>
              </div> */}
              <div>
                <img src={islandHopping} alt="Water Sport Car In Langkawi"></img>
                <div className="package-container text-center">
                  <h5>Island Hopping</h5>
                  <p className="mb-0">3 Hours</p>
                  <p className="mb-0">RM2600</p>
                  <NavLink to="/booking">
                    <button>BOOK NOW</button>
                  </NavLink>
                </div>
              </div>
              <div>
                <video loop autoPlay muted className='w-100'>
                    <source src={jetVideo1} type="video/mp4" />
              </video> 
                <div className="package-container text-center">
                  <h5>Single Trip</h5>
                  <p className="mb-0">30 minutes</p>
                  <p className="mb-0">RM1200</p>
                  <NavLink to="/booking">
                    <button>BOOK NOW</button>
                  </NavLink>
                </div>
              </div>
              <div>
                <video loop autoPlay muted className='w-100'>
                    <source src={jetVideo2} type="video/mp4" />
              </video> 
                <div className="package-container text-center">
                  <h5>Single Trip</h5>
                  <p className="mb-0">30 minutes</p>
                  <p className="mb-0">RM1200</p>
                  <NavLink to="/booking">
                    <button>BOOK NOW</button>
                  </NavLink>
                </div>
              </div>
          </div>
          {/* <div className="carDisplayRight">
            <img src={carRight} alt="Water Sport Car In Langkawi"></img>
            <h5>Let's Start Booking Now!</h5>
          </div> */}
        </div>
      </div>
    </>
  );

};

export default Packages;