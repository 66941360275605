// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import SquareLogo from './assets/img/logo.png';
import FooterLogo from './assets/img/footer-logo.png';
import series1 from './assets/img/jet-img/series1WaterSportCar.webp';
import series2 from './assets/img/jet-img/series2WaterSportCar.webp';

// Pages
import Home from "./pages/home";
import Booking from "./pages/booking";


function App() {
  const [isOpen, setIsOpen] = useState(false);

  const openSidebar = () => {
    setIsOpen(true);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setIsOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollAndClose = (sectionId) => {
    scrollToSection(sectionId);
    closeSidebar();
  };
  return (
    <Router>
      <section className="header-section">
            <div className='header-top'>
            {/* <div className="col-6 col-md-4 logo-section">
              <button className="btn p-0" onClick={(e) => {
                window.location.href= "/";
              }}>
                <img className="header-logo" src={SquareLogo}></img>
              </button>
            </div> */}

            <div className="col-6 col-md-8 text-right desktop-md menu-section">
              <div className="menu-nav-container">
              <button className="btn" onClick={() => scrollToSection('aboutus')}>
                  <p className="header-menu-nav mb-0">ABOUT US</p>
                </button>
                <button className="btn" onClick={() => scrollToSection('package')}>
                  <p className="header-menu-nav mb-0">PACKAGES</p>
                </button>
                <button className="btn" onClick={(e) => {
                  window.location.href= "/booking";
                }}>
                  <p className="header-menu-nav mb-0">BOOKING</p>
                </button>
                <button className="btn" onClick={(e) => {
                  window.location.href= "/";
                }}>
                  <p className="header-menu-nav mb-0">CONTACT</p>
                </button>
                <div className='contactNumber'>(+60)123456789</div>
              </div>
            </div>

            <div class="user-action-section">
              <button className="btn p-0" onClick={(e) => {
                  window.location.href= "/";
                }}>
                  <p className="header-menu-nav mb-0 login-btn">LOGIN</p>
                </button>
                <button className="btn p-0" onClick={(e) => {
                  window.location.href= "/";
                }}>
                  <p className="header-menu-nav mb-0 register-btn">REGISTER</p>
                </button>
            </div>
            </div>
            <div className='header-bottom'>
              <div className="logo-section">
                <button className="btn p-0" onClick={(e) => {
                  window.location.href= "/";
                }}>
                  <img className="header-logo" src={SquareLogo}></img>
                </button>
              </div>
              {/* <div className='carSeriesSection'>
                <p onClick={() => scrollToSection('series1')}>Series 1</p>
                <p onClick={() => scrollToSection('series2')}>Series 2</p>
              </div> */}
            </div>
            
            <div className='mobileHeader'>
              <div className="logo-section">
                <button className="btn p-0" onClick={(e) => {
                  window.location.href= "/";
                }}>
                  <img className="header-logo" src={SquareLogo}></img>
                </button>
              </div>
              <div className={`col-6 text-right mobile-md ${isOpen ? 'open' : ''}`}>
                <button className="btn p-0 mx-2" onClick={openSidebar}>
                  <p className="header-menu-icon mb-0"><i className="fa-sharp fa-solid fa-bars"></i></p>
                </button>
              </div>
            </div>

            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
              <div className='sidebar-top'>
                <div className="logo-section">
                  <button className="btn p-0" onClick={(e) => {
                    window.location.href= "/";
                  }}>
                    <img className="header-logo" src={SquareLogo}></img>
                  </button>
                </div>
                <div onClick={closeSidebar} className='closeIcon'>&#x2715;</div>
              </div>

              <div className='seriesSection'>
                <div className='carSeriesSection'>
                  <img className="seriesIcon" src={series1} alt='Water Sport Car in Langkawi'></img>
                  <p onClick={() => handleScrollAndClose('series1')}>Series 1</p>
                  </div>
                <div className='carSeriesSection'>
                  <img className="seriesIcon" src={series2} alt='Water Sport Car in Langkawi'></img>
                  <p onClick={() => handleScrollAndClose('series2')}>Series 2</p>
                </div>
              </div>
              
              <div className="menu-nav-container">
                <button className="btn" onClick={() => scrollToSection('aboutus')}>
                    <p className="header-menu-nav mb-0">ABOUT US</p>
                  </button>
                  <button className="btn" onClick={() => scrollToSection('package')}>
                    <p className="header-menu-nav mb-0">PACKAGES</p>
                  </button>
                  <button className="btn" onClick={(e) => {
                    window.location.href= "/booking";
                  }}>
                    <p className="header-menu-nav mb-0">BOOKING</p>
                  </button>
                  <button className="btn" onClick={(e) => {
                    window.location.href= "/";
                  }}>
                    <p className="header-menu-nav mb-0">CONTACT</p>
                  </button>
                </div>
                <div class="user-action-section">
                  <button className="btn p-0" onClick={(e) => {
                      window.location.href= "/";
                    }}>
                      <p className="header-menu-nav mb-0 login-btn">LOGIN</p>
                    </button>
                    <button className="btn p-0" onClick={(e) => {
                      window.location.href= "/";
                    }}>
                      <p className="header-menu-nav mb-0 register-btn">REGISTER</p>
                    </button>
                </div>
            </div>
      </section>

      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/booking" element={<Booking/>}/>
      </Routes>
      
    </Router>
  );
}

export default App;