import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

import SquareLogo from '../assets/img/logo.png';

const Footer = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
  
  return (
    <>
      <div className="container-fluid fluid-padding py-5 footer-section">
        <div className="row">
            <div className="col-3">
                <img className="header-logo" src={SquareLogo}></img>
            </div>
            <div className="linkColumn col-4">
                <h5>Useful Links</h5>
                <div>
                    <a onClick={() => scrollToSection('aboutus')}>About Us</a>
                    <a onClick={() => scrollToSection('package')}>Packages</a>
                    <a onClick={(e) => { window.location.href= "/booking"; }}>Booking</a>
                    <a>Contact Us</a>
                </div>
            </div>
            <div className="emailColumn col-4">
                <h5>Emails</h5>
                <div>
                    <a>abc@gmail.com</a>
                    <a>efg@gmail.com</a>
                </div>
            </div>
        </div>
        <hr></hr>
        <p className="copyright text-center text-white">Copyright © 2024 Epic Jet All Rights Reserved.</p>
      </div>
    </>
  );

};

export default Footer;